
.main-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

.main-contentContainer {
  /* height: calc(100% - 30px); */
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.main-content {
  padding:20px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */

  /* max-width: 800px; */
}


.profile-button a {
  text-decoration: none;
  color: black;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #e6e6e6;
}

.profile-button a:hover {
  background-color: #d9d9d9;
}

.logout-button button {
  border: none;
  background-color: transparent;
  color: black;
  cursor: pointer;
}

.logout-button button:hover {
  color: #ff0000;
}

@media screen and (max-width: 600px) {
  .main-content {
    padding: 10px;
  }
}