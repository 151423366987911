* {
  box-sizing: border-box;

}

body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: sans-serif;
}

.root {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow: auto;
}
